<div
  class="container"
  [style.height.px]="size"
  [style.width.px]="size"
  [class.selected]="selected"
  (click)="chooseAsset()"
>
  <img [src]="asset.thumbnailUrl" alt="Asset Thumbnail" class="image" />
  <div class="middle">
    @if (selectMode && selected) {
      <div class="remove-icon" (click)="removeAsset($event)">
        <i class="fa fa-times"></i>
      </div>
    }
  </div>
</div>
@if(withName){
  <div class='with-name'>
    {{ asset.name}}
  </div>
}
