import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventTemplateFrame } from '@models/events/event-template-frame.model';
import { TranslateModule } from '@ngx-translate/core';
import { EventTemplateAsset } from '@models/events/event-template-asset.model';

@Component({
  selector: 'app-template-asset-card',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './template-asset-card.component.html',
  styleUrl: './template-asset-card.component.scss',
})
export class TemplateAssetCardComponent {
  @Input() asset!: EventTemplateAsset | EventTemplateFrame;
  @Input() size?: number;
  @Input() selectMode = false;
  @Input() selected = false;
  @Input() withName? = false;

  @Output() readonly assetChosen = new EventEmitter<any>();

  chooseAsset() {
    if (this.selected) {
      this.removeAsset();
    } else {
      this.assetChosen.emit(this.asset);
    }
  }

  removeAsset(e?: Event) {
    if (e) {
      e.stopPropagation();
    }

    this.assetChosen.emit(undefined);
  }
}
